.logoTextBanner {
  display: flex;
  position: relative;
  align-items: center;
  padding: 0.75rem 2.5rem;
  background-color: rgb(246 206 70);
  color: rgb(0 0 0);

  + .logoTextBanner {
    margin-top: 1rem;
  }

  @mixin mobile {
    padding-inline: 0.625rem;
  }
}

.logo {
  flex-shrink: 0;
  max-height: 2.25rem;
  max-width: 25%;
  width: auto;
  height: auto;
}

.content {
  margin-left: 1rem;

  @mixin desktop {
    margin-left: 1.25rem;
  }

  /* content might be rendered in backend from markdown in wrapped in a paragraph p tag */
  p {
    display: inline;
  }
}

.bannerTextContent {
  margin-inline-end: var(--betterplace-spacing-200);
}

.gentleLink {
  color: inherit;
  font-weight: inherit;
  text-decoration: underline;

  &:hover,
  &:focus-visible {
    text-decoration: none;
  }
}

@mixin mobile {
  .logoTextBanner {
    padding-inline-start: 1.25rem;
  }
}
